import { DetailModalLink } from '@/components/ui/detailModalLink';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import type { Address, FieldValue } from '@pigello/pigello-matrix';

import type { Entries, Values } from './types';
import { Urls } from './urls';

export function raise(err: string): never {
  throw new Error(err);
}

export const ObjectEntries = <T extends object>(obj: T) =>
  Object.entries(obj) as Entries<T>;

export const ObjectValues = <T extends object>(obj: T) =>
  Object.values(obj) as Values<T>;

export const ObjectKeys = <T extends object>(obj: T) =>
  Object.keys(obj) as (keyof T)[];

/**
 * @description
 * 
 * @param data - The data to check.
 * @param key - The key to check for.
 * @returns True if the key exists in the data and the value is not null or undefined. False otherwise 
 * @example
 * ```tsx
 * type Data = { name: string; age: number };
 * const data = { name: 'John', age: 42 };
 * const name = typeGuard<Data>(data, 'name') ? data.name : 'No name';
 * console.log(name); // 'John'
 * ```
 * @example
 * ```tsx
 *   const corporateName = typeGuard<Tenant>(data, 'corporateName')
    ? data.corporateName
    : false;

  if (corporateName) {
    return corporateName;
  }...
 * ```
 */
export function typeGuard<T>(data: unknown, key: keyof T): data is T {
  return (
    typeof data === 'object' &&
    data != null &&
    key in data &&
    data?.[key as keyof typeof data] != null
  );
}

export function isDev() {
  return true;
}

export const camelToSnake = (str: string) => {
  return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
};

export function toSqm(value: string | number | undefined | null) {
  if (!value) return '-';

  if (typeof value === 'string' && value.length === 0) return '-';

  return `${value.toLocaleString('sv', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })} ㎡`;
}

export function toWeekInterval(weeks: FieldValue<string>) {
  if (!weeks) return '-';
  const intWeeks = parseInt(weeks);
  if (intWeeks <= 2) {
    return intWeeks === 1 ? intWeeks + ' vecka' : `${intWeeks} veckor`;
  }

  return intWeeks / 4 === 1
    ? intWeeks / 4 + ' månad'
    : intWeeks / 4 + ' månader';
}
export function toReadableAddress(
  address?: Partial<Address> | null,
  long = true,
  withDetailLink = true
) {
  if (!address) return null;

  if (!address.street) return '-';

  const readable = `${address.street ?? ''} ${address.streetNumber ?? ''}${
    long ? `, ${address.postalCode ?? ''}, ${address.city ?? ''}` : ''
  }`;

  if (withDetailLink) {
    return (
      <Tooltip>
        <TooltipContent>{readable}</TooltipContent>
        <TooltipTrigger>
          <DetailModalLink
            className='inline-block max-w-48 truncate'
            pathname={Urls.detail.address.overview(address.id)}
          >
            {readable}
          </DetailModalLink>
        </TooltipTrigger>
      </Tooltip>
    );
  }

  return readable;
}

export const DELIVERY_METHOD_CODES = {
  SMS: 0,
  EMAIL: 1,
  DIGITAL_INBOX: 2,
  MAIL: 3,
  E_INVOICE_BANK: 4,
  E_INVOICE_PEPPOL_SVEFAKTURA: 5,
} as const;

export const DELIVERY_METHOD_MAP: Record<number, string> = {
  0: 'SMS',
  1: 'E-post',
  2: 'Digital brevlåda',
  3: 'Post',
  4: 'E-faktura (bank)',
  5: 'E-faktura (PEPPOL/Svefaktura)',
};

export function toReadableDistributionMethod(
  distributionArray: number[][] | null
) {
  if (!distributionArray || distributionArray.length === 0) return '-';

  const primary = distributionArray[0];

  return primary.map((method) => DELIVERY_METHOD_MAP[method]).join(', ');
}

export function toReadableDayInMonth(day: string | number | undefined) {
  if (!day) {
    return '-';
  }
  const val = day.toString();

  const lastChar = val[val.length - 1];

  if (['1', '2'].includes(lastChar)) return `${val}:a`;

  return `${val}:e`;
}
export function toReadableDayInterval(day?: string | number | null) {
  if (!day) return '-';
  const val = day.toString();

  if (val === '1') return 'varje';
  if (val === '2') return 'varannan';

  return `var ${val}:e`;
}

export function toReadableAddressAnonymized(
  address?: Partial<Address> | null | 'Anonymiserad' | string,
  long = true,
  withGoogleLink = true
) {
  if (address === 'Anonymiserad' || typeof address === 'string') {
    return 'Anonymiserad';
  }

  return toReadableAddress(address, long, withGoogleLink);
}

export function toMoneyString(value: FieldValue<number>) {
  if (value == null) return '-';
  return `${value.toLocaleString('sv', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })} kr`;
}

export function toPercentageString(
  value?: FieldValue<number> | string | number
) {
  if (value == null) return '-';
  return `${value}%`;
}

export const rentSqm = (rent: number, sqm: FieldValue<number>) => {
  if (!sqm) return '-';
  return `${toMoneyString(rent / sqm)}`;
};

type AnonymizeProps<T> = {
  anonymized?: boolean;
  anonymizationName?: FieldValue<string>;
  value: T;
};

export function handleAnonymizationValue<T>({
  anonymized,
  anonymizationName,
  value,
}: AnonymizeProps<T>) {
  if (!anonymized) {
    return value;
  }

  if (value !== undefined && anonymized) {
    return value;
  }

  if (value === undefined && anonymizationName && anonymized) {
    return anonymizationName;
  }

  if (value === undefined && anonymized) {
    return 'Anonymiserad';
  }
  return undefined;
}
